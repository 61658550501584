


























































import Vue from "vue";
import { required, email } from "vuelidate/lib/validators";
import FieldGroup from "@/components/form/FieldGroup.vue";
import Loader from "@/components/layout/Loader.vue";
import FieldError from "@/components/form/FieldError.vue";
import { Option } from "./EnumSelect.vue";
import { BDropdown } from "bootstrap-vue";

export type PickerOption<V> = Option<V> & {
  key: string;
};

export default Vue.extend({
  name: "PickerSelect",
  components: { FieldGroup, FieldError, Loader },
  props: {
    getText: {
      type: Function,
      default: function(option: PickerOption<unknown>) {
        return option.text;
      }
    },
    getValue: {
      type: Function,
      default: function(option: PickerOption<unknown>) {
        return option;
      }
    },
    getKey: {
      type: Function,
      default: function(option: PickerOption<unknown>) {
        return option.key;
      }
    },
    value: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    placeholder: {
      type: String
    },
    select: {
      type: Boolean,
      default: false
    },
    defaultValue: String,
    purpleLabel: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    async: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.$emit("filter", "");
    // If a value already exists, then component uses it as selected value
    if (this.value) {
      this.selected = this.getOptionByValue(this.value);
    } else {
      // Else it emits the default value for the parent component
      this.selected = this.getOptionByValue(this.defaultValue);
      this.$emit("input", this.defaultValue);
    }
  },
  watch: {
    value: {
      handler(val) {
        this.selected = this.getOptionByValue(val);
        this.filter = "";
        this.v.$reset();
        this.$v.$reset();
      },
      immediate: true
    }
  },
  data() {
    return {
      selected: null as unknown,
      filter: ""
    };
  },
  methods: {
    onInput(filter: string) {
      if (!this.async) {
        this.filter = filter;
      }
      this.$emit("filter", filter);
    },
    getOptionByValue(value: unknown) {
      const member = this.computedOptions.find(o => o.value === value);

      // @ts-ignore
      if (member && member.value && member.value.email) {
        // @ts-ignore
        member.value.email = member.value.email.toLowerCase();
      }

      return member;
    },
    focusFilter() {
      const defaultEl = this.$el.querySelector(
        `a[value="${this.defaultValue}"]`
      );
      if (defaultEl) {
        (defaultEl as HTMLElement).focus();
      } else {
        const firstEl = this.$el.querySelector(".picker-filter");
        (firstEl as HTMLElement).focus();
      }
    },
    onEnter(e: KeyboardEvent) {
      this.filter = (e?.target as HTMLInputElement).value;
      this.$v.$touch();
      if (!this.$v.$anyError) {
        this.$emit("keyenter", this.filter);
        (this.$refs["picker-dropdown"] as BDropdown).hide(true);
      }
    }
  },
  computed: {
    state(): boolean | null {
      return this.v.$dirty && this.v.$error ? false : null;
    },
    computedOptions(): Option<unknown>[] {
      return this.options.map(o => ({
        value: this.getValue(o),
        text: this.getText(o)
      }));
    },
    filteredOptions(): Option<unknown>[] {
      return [
        ...this.computedOptions.filter(o =>
          o.text
            .toLowerCase()
            .trim()
            .includes(this.filter.toLowerCase().trim())
        )
      ];
    }
  },
  validations: {
    filter: {
      required,
      email
    }
  }
});
