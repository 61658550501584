


















































































import formMixin from "@/mixins/form";
import FormField from "@/components/form/FormField.vue";
import PlanSelect from "@/components/form/PlanSelect.vue";
import Form from "@/components/form/Form.vue";
import TimezonePicker from "@/components/form/TimezonePicker.vue";
import FileUpload from "@/components/form/FileUpload.vue";
import { required, email } from "vuelidate/lib/validators";
import mixins from "vue-typed-mixins";
import {
  OrganizationWorkspace,
  UpdateOrganizationWorkspaceInput
} from "@/generated/graphql";
import { ActionTypes } from "@/store/action-types";
import { PropType } from "vue";
import cloneDeep from "lodash.clonedeep";
import { RuleDecl } from "vue/types/options";

export default mixins(formMixin).extend({
  props: {
    workspace: {
      type: Object as PropType<OrganizationWorkspace>
    }
  },
  components: {
    PlanSelect,
    FileUpload,
    FormField,
    TimezonePicker,
    Form
  },
  data() {
    return {
      form: {
        company: {
          name: "",
          logoUrl: ""
        },
        contact: {
          email: "",
          firstName: "",
          lastName: ""
        },
        locale: {
          timezone: ""
        },
        scope: "COMPANY"
      } as UpdateOrganizationWorkspaceInput,
      selectedPlan: null as string | null
    };
  },
  watch: {
    workspace: {
      handler: function(ws: OrganizationWorkspace) {
        if (ws) {
          const { company, contact, locale } = ws;
          this.form = cloneDeep({ company, contact, locale });
        }
      },
      immediate: true
    }
  },
  validations(): RuleDecl {
    const validation = {
      form: {
        company: {
          name: {
            required
          },
          logoUrl: {}
        },
        contact: {
          email: {
            required,
            email
          },
          firstName: {
            required
          },
          lastName: {
            required
          }
        },
        locale: {
          timezone: {
            required
          }
        }
      }
    };
    return this.isEditMode
      ? validation
      : {
          ...validation,
          selectedPlan: {
            required
          }
        };
  },
  computed: {
    isEditMode(): boolean {
      return Boolean(this.workspace);
    },
    wsKey(): string {
      return this.isEditMode ? this.$route.params.id : "";
    }
  },
  methods: {
    async onSubmit() {
      if (this.isEditMode) {
        await this.$storeTyped.dispatch(
          ActionTypes.UPDATE_CURRENT_WORKSPACE,
          this.form
        );
      } else {
        await this.$storeTyped.dispatch(ActionTypes.CREATE_WORKSPACE, {
          input: this.form,
          priceId: this.selectedPlan
        });
      }
    }
  }
});
