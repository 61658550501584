






















import Vue, { PropType } from "vue";
import FieldGroup from "@/components/form/FieldGroup.vue";
import { Validation } from "vuelidate";

export type Option<V> = {
  value: V;
  text: string;
};

export default Vue.extend({
  name: "EnumSelect",
  components: { FieldGroup },
  props: {
    getText: {
      type: Function,
      default: function(option: Option<unknown>) {
        return option.text;
      }
    },
    getValue: {
      type: Function,
      default: function(option: Option<unknown>) {
        return option;
      }
    },
    getCode: {
      type: Function,
      default: function(option: Option<unknown> | null) {
        return option?.value;
      }
    },
    value: {
      type: [Object, String]
    },
    options: {
      type: Array,
      required: true
    },
    v: {
      type: Object as PropType<Validation>,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledValues: {
      type: Array as PropType<string[]>,
      default: () => []
    }
  },
  computed: {
    state(): boolean | null {
      return this.v.$dirty && this.v.$error ? false : null;
    },
    computedOptions(): Option<unknown>[] {
      return this.options.map(o => ({
        value: this.getValue(o),
        text: this.getText(o),
        disabled: this.disabledValues.includes(this.getValue(o))
      }));
    }
  },
  watch: {
    value: {
      handler(val: Option<unknown>) {
        const s =
          this.computedOptions.find(
            o => this.getCode(o.value) === this.getCode(val)
          )?.value || null;
        this.selected = s;
      },
      immediate: true
    }
  },
  data() {
    return {
      selected: null as unknown
    };
  },
  methods: {
    setValue(value: unknown) {
      this.selected = value;
      this.$emit("input", value);
      this.v.$reset();
    }
  }
});
