






import Vue from "vue";
import LayoutWithPageHeader from "@/components/layout/LayoutWithPageHeader.vue";
import WorkspaceForm from "@/views/workspaces/WorkspaceForm.vue";

export default Vue.extend({
  components: {
    LayoutWithPageHeader,
    WorkspaceForm
  }
});
