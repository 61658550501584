














import modelMixin from "@/mixins/model.ts";
import EnumSelect from "@/components/form/EnumSelect.vue";
import mixins from "vue-typed-mixins";
import { ActionTypes } from "@/store/action-types";
import { EnhancedPlan } from "@/models/organization";

export default mixins(modelMixin<string>()).extend({
  name: "PlanSelect",
  components: { EnumSelect },
  props: {
    value: {
      type: String
    },
    v: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: "plan-select"
    },
    wsId: {
      type: String
    },
    label: {
      type: String,
      default: "Plan"
    },
    notNull: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.$storeTyped
      .dispatch(ActionTypes.FETCH_PLANS)
      .then((plans: EnhancedPlan[]) => {
        if (!this.wsId) {
          this.eligiblePlanIds = plans.map(p => p.id);
        }
      });

    if (this.wsId) {
      this.$storeTyped
        .dispatch(ActionTypes.FETCH_CURRENT_WORKSPACE_ELIGIBLE_PLANS, this.wsId)
        .then(ids => (this.eligiblePlanIds = ids));
    }
  },
  data() {
    return {
      eligiblePlanIds: null as string[] | null
    };
  },
  computed: {
    plans(): EnhancedPlan[] {
      return this.$storeTyped.getters.getPlans;
    },
    options(): EnhancedPlan[] {
      return this.eligiblePlanIds ? this.plans : [];
    },
    eligibleOptions(): EnhancedPlan[] {
      return this.plans?.filter(p => this.eligiblePlanIds?.includes(p.id));
    },
    disabledIds(): string[] {
      return this.plans
        .map(p => p.id)
        .filter(id => !this.eligiblePlanIds?.includes(id));
    }
  },
  watch: {
    plans() {
      if (
        this.notNull &&
        // @ts-ignore
        (this.plans.length > 0) & this.eligibleOptions &&
        this.eligibleOptions.length > 0
      ) {
        // @ts-ignore
        this.localValue = this.eligibleOptions[0].id;
      }
    }
  }
});
