import Vue, { PropType } from "vue";

/**
 * Mixin allowing to pass the reference of v-model directive to child components.
 *
 */

const modelMixin = <V = unknown>() =>
  Vue.extend({
    props: {
      value: {
        type: Object as PropType<V>
      }
    },
    computed: {
      localValue: {
        get(): V {
          return this.value as V;
        },
        set(newValue: V): void {
          this.$emit("input", newValue);
        }
      }
    }
  });

export default modelMixin;
