
















import modelMixin from "@/mixins/model.ts";
import PickerSelect from "./PickerSelect.vue";
import { timezones } from "@/list";
import mixins from "vue-typed-mixins";

export default mixins(modelMixin<object | string>()).extend({
  name: "TimezonePicker",
  components: { PickerSelect },

  props: {
    value: {
      type: [Object, String]
    },
    v: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      default: "timezone-picker"
    },
    label: {
      type: String,
      default: "Timezone"
    },
    placeholder: {
      type: String,
      default: "Pick a timezone"
    }
  },
  data() {
    return { timezones };
  },
  computed: {
    defaultTimezone() {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }
});
